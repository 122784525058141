import React, { useState, useEffect } from 'react'
import '../style/cart.css'
import '../style/order.scss'
import OrderImg from '../images/Remedy-rafiki.svg'
import Footer from '../components/homeNav/footer/Footer'
import axios from 'axios'
import { format } from 'date-fns'
import PageNav from '../components/homeNav/PageNav'
import BotNav from '../components/bottomNav'
import {
  Container,
  Wrapper,
  Title,
  OrderTop,
  TopButton,
  TopButton2,
  TopButton3,
  TopTexts,
  TopText,
  Bottom,
  Info,
  Product,
  ProductDetail,
  Details,
  ProductName,
  ProductId,
  ProductSize,
  Hr,
  OrderImgWrap,
  PrescriptionImg,
  OrderImage,
} from '../style/cartstyles'
import useAuthState from '../stores/auth'

const Orders = () => {
  const { user, isLoggedIn } = useAuthState()

  const [orders, setOrders] = useState([])
  const [pres, setPres] = useState([])
  const [error, setError] = React.useState('')
  const [loading, setLoading] = React.useState(false)

  const fetchOrders = async () => {
    try {
      setLoading(true)
      const result = await axios.get(
        `${process.env.GATSBY_API_URL}/users/${user.id}/orders`,
      )
      setOrders(result.data.data)
      console.log('orders', result.data.data)
      setLoading(false)
    } catch (err) {
      setLoading(false)
      const { message } = err.response.data
      setError(message)
    }
  }

  const fetchPres = async () => {
    try {
      setLoading(true)
      const result = await axios.get(
        `${process.env.GATSBY_API_URL}/users/${user.id}/prescriptions`,
      )
      setPres(result.data.data)
      setLoading(false)
    } catch (err) {
      setLoading(false)
      const { message } = err.response.data
      setError(message)
    }
  }

  useEffect(() => {
    if (isLoggedIn()) {
      fetchOrders()
      fetchPres()
    }
  }, [])

  const getCustomBrandName = item => {
    return `${item.product.productName} ${
      item.product.formulation !== 'not applicable'
        ? item.product.formulation
        : ''
    } 
    ${
      item.product.strength !== 'not applicable' ? item.product.strength : ''
    } ${item.product.packSize}`
  }

  const updateOrderStatus = async id => {
    try {
      setLoading(true)
      await axios.patch(
        `${process.env.GATSBY_API_URL}/orders/${id}/processcancel/`,
      )
      setLoading(false)
      /*  isBrowser && window.location.reload() */
      fetchOrders()
    } catch (err) {
      setLoading(false)
      const { message } = err.response.data
      setError(message)
    }
  }

  const updateOrderStatusReceived = async id => {
    try {
      setLoading(true)
      await axios.patch(
        `${process.env.GATSBY_API_URL}/orders/${id}/processreceived/`,
      )
      setLoading(false)
      /* isBrowser && window.location.reload() */
      fetchOrders()
    } catch (err) {
      setLoading(false)
      const { message } = err.response.data
      setError(message)
    }
  }

  const updateOrderStatusConfirm = async id => {
    try {
      setLoading(true)
      await axios.patch(
        `${process.env.GATSBY_API_URL}/orders/${id}/processconfirm/`,
      )
      setLoading(false)
      fetchOrders()
    } catch (err) {
      setLoading(false)
      const { message } = err.response.data
      setError(message)
    }
  }

  const updatePresStatus = async id => {
    try {
      setLoading(true)
      await axios.patch(
        `${process.env.GATSBY_API_URL}/prescriptions/${id}/processcancel/`,
      )
      setLoading(false)
      /* isBrowser && window.location.reload() */
      fetchPres()
    } catch (err) {
      setLoading(false)
      const { message } = err.response.data
      setError(message)
    }
  }

  const updatePresStatusReceived = async id => {
    try {
      setLoading(true)
      await axios.patch(
        `${process.env.GATSBY_API_URL}/prescriptions/${id}/processreceived/`,
      )
      setLoading(false)
      /*  isBrowser && window.location.reload() */
      fetchPres()
    } catch (err) {
      setLoading(false)
      const { message } = err.response.data
      setError(message)
    }
  }

  const updatePresStatusConfirm = async id => {
    try {
      setLoading(true)
      await axios.patch(
        `${process.env.GATSBY_API_URL}/prescriptions/${id}/processconfirm/`,
      )
      setLoading(false)
      /*  isBrowser && window.location.reload() */
      fetchPres()
    } catch (err) {
      setLoading(false)
      const { message } = err.response.data
      setError(message)
    }
  }

  const [active, setActive] = useState(null)
  const [list, setList] = useState('order')
  const [showPrescription, setShowPrescription] = useState(false)

  return (
    <>
      <Container>
        <PageNav />
        {loading && (
          <div
            style={{
              position: 'fixed',
              left: '50%',
              top: '50%',
              transform: 'translate(-50%, -50%)',
              border: '1px solid blue',
              borderRadius: '10%',
              padding: '10px',
              backgroundColor: 'white', // Optional: Set background color
              zIndex: 999, // Optional: Set z-index to make sure it appears above other elements
            }}
          >
            Loading...
          </div>
        )}

        <Wrapper>
          <Title>Your Orders</Title>
          <OrderTop>
            <TopButton to="/pharmacy">Continue Shopping</TopButton>
            <p style={{ color: 'red', textAlign: 'center' }}>{error}</p>
            <TopTexts>
              <TopText>Cart({orders.length})</TopText>
              {/* <TopText> Wishlist (0)</TopText> */}
            </TopTexts>
          </OrderTop>
          <TopButton3
            style={{
              marginRight: '3px',
              background: list === 'order' && '#808080',
            }}
            onClick={() => setList('order')}
          >
            Cart Orders
          </TopButton3>
          <TopButton3
            style={{ background: list === 'prescription' && '#808080' }}
            onClick={() => setList('prescription')}
          >
            Prescription
          </TopButton3>

          <Bottom>
            {list === 'order' && (
              <Info>
                {orders.length === 0 && <div>No orders found</div>}
                {orders
                  .sort(
                    (a, b) => Date.parse(b.updatedAt) - Date.parse(a.updatedAt),
                  )
                  .map((item, index) => {
                    return (
                      <span key={index}>
                        <button
                          key={item.id}
                          className="order-list-item"
                          open={active === item.id || false}
                          onClick={e => {
                            //e.preventDefault()
                            if (active === item.id) {
                              setActive(null)
                            } else {
                              setActive(item.id)
                            }
                          }}
                        >
                          <header className="order-list-item-title">
                            <Product key={index}>
                              <ProductDetail>
                                {/* <Image src="https://res.cloudinary.com/tripleaim-software/image/upload/v1656845131/afyabook%20images/drugplaceholderImg_mpt3rj.png" /> */}
                                <Details>
                                  <ProductName>
                                    <b>Product: </b>
                                    {getCustomBrandName(item)}
                                  </ProductName>
                                  <ProductSize>
                                    <b>Unit:</b> {item.product.unit}
                                    <br />
                                    {/* {item.product?.prescription && (<div>
                                      <b>Prescription:</b>{" "}
                                      <a href={item.product.prescription} target="_blank" rel="noopener noreferrer">
                                        prescription
                                      </a>

                                      <br />
                                      </div>
                                    )}                                     */}
                                    {item.product?.prescription && (
                                      <div>
                                        <ProductId>
                                          <b>Prescription: </b>{' '}
                                          {!showPrescription ? (
                                            <TopButton3
                                              onClick={() =>
                                                setShowPrescription(
                                                  !showPrescription,
                                                )
                                              }
                                            >
                                              View
                                            </TopButton3>
                                          ) : (
                                            <TopButton3
                                              onClick={() =>
                                                setShowPrescription(
                                                  !showPrescription,
                                                )
                                              }
                                            >
                                              Hide
                                            </TopButton3>
                                          )}
                                        </ProductId>
                                        {showPrescription && (
                                          <PrescriptionImg
                                            src={item.product.prescription}
                                          />
                                        )}
                                        <br />
                                      </div>
                                    )}
                                    <b>Quantity Requested: </b>
                                    {item.product.reqQuantity}
                                    <br />
                                    {item.orderStatus !== 'order sent' && (
                                      <>
                                        <b>Quantity Issued: </b>
                                        {item.product?.quantity || 0}
                                      </>
                                    )}
                                    <br />
                                    <b>Product Price @Ksh: </b>
                                    {item.product.sellingPrice}
                                    <br />
                                    {!!item.product.discount?.value && (
                                      <>
                                        <b>Product Discount: </b>
                                        {item.product.discount?.value}%
                                      </>
                                    )}
                                  </ProductSize>
                                  {/* <ProductId>
                                <b>Pharmacy: </b> {item.product.shop.name}
                              </ProductId> */}
                                  {(item.orderStatus === 'receive' ||
                                    item.orderStatus === 'review' ||
                                    item.orderStatus === 'received' ||
                                    (item.orderStatus === 'confirmed' &&
                                      item.discount?.length > 0)) && (
                                    <ProductId>
                                      <b>Cost Ksh: </b>{' '}
                                      {item.product.totalProductPrice}
                                    </ProductId>
                                  )}
                                  {!!item?.discount?.value && (
                                    <ProductId>
                                      <b>Discount: </b>{' '}
                                      {item.discount.type === 'Percentage'
                                        ? item.discount.value + '%'
                                        : item.discount.type ===
                                            'Price Override'
                                          ? item.product.totalProductPrice -
                                            item.discount.value +
                                            ' Ksh'
                                          : item.discount.value + ' Ksh'}
                                    </ProductId>
                                  )}
                                  <ProductId>
                                    <b>Total Cost Ksh: </b> {item.totalPrice}
                                  </ProductId>
                                  <ProductId>
                                    <b>Ordered Date: </b>{' '}
                                    {format(
                                      new Date(item.createdAt),
                                      'yyyy-MM-dd hh:mm aa',
                                    )}
                                  </ProductId>
                                  {item.orderStatus === 'cancelled' && (
                                    <ProductId>
                                      {item.quoteTime && (
                                        <>
                                          <b>Quote Date: </b>{' '}
                                          {format(
                                            new Date(item.quoteTime),
                                            'yyyy-MM-dd hh:mm aa',
                                          )}
                                        </>
                                      )}
                                      {item.confirmTime && (
                                        <div>
                                          <b>Confirmed Date: </b>{' '}
                                          {format(
                                            new Date(item.confirmTime),
                                            'yyyy-MM-dd hh:mm aa',
                                          )}
                                        </div>
                                      )}
                                      {item.endSaleTime && (
                                        <div>
                                          <b>Sale Date: </b>{' '}
                                          {format(
                                            new Date(item.endSaleTime),
                                            'yyyy-MM-dd hh:mm aa',
                                          )}
                                        </div>
                                      )}
                                      <div>
                                        <b>Cancellation Date: </b>{' '}
                                        {format(
                                          new Date(item.cancellationTime),
                                          'yyyy-MM-dd hh:mm aa',
                                        )}
                                      </div>
                                    </ProductId>
                                  )}

                                  {item.orderStatus === 'receive' && (
                                    <ProductId>
                                      {item.quoteTime && (
                                        <div>
                                          <b>Quote Date: </b>{' '}
                                          {format(
                                            new Date(item.quoteTime),
                                            'yyyy-MM-dd hh:mm aa',
                                          )}
                                        </div>
                                      )}
                                      {item.confirmTime && (
                                        <div>
                                          <b>Confirmed Date: </b>{' '}
                                          {format(
                                            new Date(item.confirmTime),
                                            'yyyy-MM-dd hh:mm aa',
                                          )}
                                        </div>
                                      )}
                                      <div>
                                        <b>Sale Date: </b>{' '}
                                        {format(
                                          new Date(item.endSaleTime),
                                          'yyyy-MM-dd hh:mm aa',
                                        )}
                                      </div>
                                    </ProductId>
                                  )}

                                  {item.orderStatus === 'received' && (
                                    <ProductId>
                                      {item.quoteTime && (
                                        <div>
                                          <b>Quote Date: </b>{' '}
                                          {format(
                                            new Date(item.quoteTime),
                                            'yyyy-MM-dd hh:mm aa',
                                          )}
                                        </div>
                                      )}
                                      {item.confirmTime && (
                                        <div>
                                          <b>Confirmed Date: </b>{' '}
                                          {format(
                                            new Date(item.confirmTime),
                                            'yyyy-MM-dd hh:mm aa',
                                          )}
                                        </div>
                                      )}
                                      {item.endSaleTime && (
                                        <div>
                                          <b>Sale Date: </b>{' '}
                                          {format(
                                            new Date(item.endSaleTime),
                                            'yyyy-MM-dd hh:mm aa',
                                          )}
                                        </div>
                                      )}
                                      <div>
                                        <b>Received Date: </b>{' '}
                                        {format(
                                          new Date(item.receiveTime),
                                          'yyyy-MM-dd hh:mm aa',
                                        )}
                                      </div>
                                    </ProductId>
                                  )}

                                  {item.orderStatus === 'confirmed' && (
                                    <ProductId>
                                      {item.quoteTime && (
                                        <div>
                                          <b>Quote Date: </b>{' '}
                                          {format(
                                            new Date(item.quoteTime),
                                            'yyyy-MM-dd hh:mm aa',
                                          )}
                                        </div>
                                      )}
                                      <div>
                                        <b>Confirmed Date: </b>{' '}
                                        {format(
                                          new Date(item.confirmTime),
                                          'yyyy-MM-dd hh:mm aa',
                                        )}
                                      </div>
                                    </ProductId>
                                  )}

                                  {item.orderStatus === 'review' && (
                                    <ProductId>
                                      <b>Quote Date: </b>{' '}
                                      {format(
                                        new Date(item.quoteTime),
                                        'yyyy-MM-dd hh:mm aa',
                                      )}
                                    </ProductId>
                                  )}

                                  <br />
                                  <ProductId>
                                    <p
                                      style={{
                                        color: 'red',
                                        textAlign: 'center',
                                      }}
                                    >
                                      {error}
                                    </p>
                                    <b>Order Status: </b>{' '}
                                    <TopButton2>
                                      {item.orderStatus === 'review' ? (
                                        <>
                                          <div>{item.orderStatus}</div>
                                          <TopButton3
                                            disabled={loading}
                                            onClick={e =>
                                              updateOrderStatusConfirm(
                                                item._id,
                                              ) && e.preventDefault()
                                            }
                                          >
                                            confirm
                                          </TopButton3>
                                          <TopButton3
                                            disabled={loading}
                                            onClick={e =>
                                              updateOrderStatus(item._id) &&
                                              e.preventDefault()
                                            }
                                          >
                                            cancel
                                          </TopButton3>
                                        </>
                                      ) : item.orderStatus === 'receive' ? (
                                        <>
                                          <div>processed</div>
                                          <TopButton3
                                            disabled={loading}
                                            onClick={e =>
                                              updateOrderStatusReceived(
                                                item._id,
                                              ) && e.preventDefault()
                                            }
                                          >
                                            {item.orderStatus}
                                          </TopButton3>
                                          <TopButton3
                                            disabled={loading}
                                            onClick={e =>
                                              updateOrderStatus(item._id) &&
                                              e.preventDefault()
                                            }
                                          >
                                            cancel
                                          </TopButton3>
                                        </>
                                      ) : (
                                        item.orderStatus
                                      )}
                                    </TopButton2>
                                  </ProductId>
                                </Details>
                              </ProductDetail>
                            </Product>
                          </header>
                        </button>
                        <Hr />
                      </span>
                    )
                  })}
              </Info>
            )}
            {list === 'prescription' && (
              <Info>
                {pres.length === 0 && <div>No prescriptions found</div>}
                {pres
                  .sort(
                    (a, b) => Date.parse(b.updatedAt) - Date.parse(a.updatedAt),
                  )
                  .map((item, index) => {
                    return (
                      <span key={index}>
                        <button
                          key={item.id}
                          className="order-list-item"
                          open={active === item.id || false}
                          onClick={e => {
                            //e.preventDefault()
                            if (active === item.id) {
                              setActive(null)
                            } else {
                              setActive(item.id)
                            }
                          }}
                        >
                          <header className="order-list-item-title">
                            <Product key={index}>
                              <ProductDetail>
                                {/* <Image src="https://res.cloudinary.com/tripleaim-software/image/upload/v1656845131/afyabook%20images/drugplaceholderImg_mpt3rj.png" /> */}
                                <Details>
                                  {item.product.map(elem => (
                                    <>
                                      <ProductName>
                                        <b>Product: </b>
                                        {`${elem.productName} ${
                                          elem.formulation !== 'not applicable'
                                            ? elem.formulation
                                            : ''
                                        } 
                                 ${
                                   elem.strength !== 'not applicable'
                                     ? elem.strength
                                     : ''
                                 } ${elem.packSize} `}
                                      </ProductName>
                                      <ProductSize>
                                        <b>Unit:</b> {elem.unit}
                                        <br />
                                        <b>Quantity:</b>
                                        {elem.quantity}
                                        <br />
                                        <b>Product Price @Kes: </b>
                                        {elem.sellingPrice}
                                        <br />
                                        {!!elem.discount?.value && (
                                          <>
                                            <b>Product Discount: </b>
                                            {elem.discount?.value}%
                                          </>
                                        )}
                                        <br />
                                        <b>Total Product Price Ksh: </b>
                                        {elem.totalProductPrice}
                                        <br />
                                        <br />
                                      </ProductSize>
                                    </>
                                  ))}
                                  {!!item.discount?.value && (
                                    <ProductId>
                                      <b>Discount: </b>{' '}
                                      {item.discount.type === 'Percentage'
                                        ? item.discount.value + '%'
                                        : item.discount.type ===
                                            'Price Override'
                                          ? item.totalPrice -
                                            item.discount.value +
                                            ' Ksh'
                                          : item.discount.value + ' Ksh'}
                                    </ProductId>
                                  )}
                                  <ProductId>
                                    <b>Overall Total Cost Ksh: </b>{' '}
                                    {item.totalPrice}
                                  </ProductId>
                                  <ProductId>
                                    <b>Ordered Date: </b>{' '}
                                    {format(
                                      new Date(item.createdAt),
                                      'yyyy-MM-dd hh:mm aa',
                                    )}
                                  </ProductId>
                                  {item.orderStatus === 'cancelled' && (
                                    <ProductId>
                                      {item.quoteTime && (
                                        <div>
                                          <b>Quote Date: </b>{' '}
                                          {format(
                                            new Date(item.quoteTime),
                                            'yyyy-MM-dd hh:mm aa',
                                          )}
                                        </div>
                                      )}
                                      {item.confirmTime && (
                                        <div>
                                          <b>Confirmed Date: </b>{' '}
                                          {format(
                                            new Date(item.confirmTime),
                                            'yyyy-MM-dd hh:mm aa',
                                          )}
                                        </div>
                                      )}
                                      {item.endSaleTime && (
                                        <div>
                                          <b>Sale Date: </b>{' '}
                                          {format(
                                            new Date(item.endSaleTime),
                                            'yyyy-MM-dd hh:mm aa',
                                          )}
                                        </div>
                                      )}
                                      <div>
                                        <b>Cancellation Date: </b>{' '}
                                        {format(
                                          new Date(item.cancellationTime),
                                          'yyyy-MM-dd hh:mm aa',
                                        )}
                                      </div>
                                    </ProductId>
                                  )}

                                  {item.orderStatus === 'receive' && (
                                    <ProductId>
                                      {item.quoteTime && (
                                        <div>
                                          <b>Quote Date: </b>{' '}
                                          {format(
                                            new Date(item.quoteTime),
                                            'yyyy-MM-dd hh:mm aa',
                                          )}
                                        </div>
                                      )}
                                      {item.confirmTime && (
                                        <div>
                                          <b>Confirmed Date: </b>{' '}
                                          {format(
                                            new Date(item.confirmTime),
                                            'yyyy-MM-dd hh:mm aa',
                                          )}
                                        </div>
                                      )}
                                      <div>
                                        <b>Sale Date: </b>{' '}
                                        {format(
                                          new Date(item.endSaleTime),
                                          'yyyy-MM-dd hh:mm aa',
                                        )}
                                      </div>
                                    </ProductId>
                                  )}

                                  {item.orderStatus === 'received' && (
                                    <ProductId>
                                      {item.quoteTime && (
                                        <div>
                                          <b>Quote Date: </b>{' '}
                                          {format(
                                            new Date(item.quoteTime),
                                            'yyyy-MM-dd hh:mm aa',
                                          )}
                                        </div>
                                      )}
                                      {item.confirmTime && (
                                        <div>
                                          <b>Confirmed Date: </b>{' '}
                                          {format(
                                            new Date(item.confirmTime),
                                            'yyyy-MM-dd hh:mm aa',
                                          )}
                                        </div>
                                      )}
                                      {item.endSaleTime && (
                                        <div>
                                          <b>Sale Date: </b>{' '}
                                          {format(
                                            new Date(item.endSaleTime),
                                            'yyyy-MM-dd hh:mm aa',
                                          )}
                                        </div>
                                      )}
                                      <div>
                                        <b>Received Date: </b>{' '}
                                        {format(
                                          new Date(item.receiveTime),
                                          'yyyy-MM-dd hh:mm aa',
                                        )}
                                      </div>
                                    </ProductId>
                                  )}

                                  {item.orderStatus === 'confirmed' && (
                                    <ProductId>
                                      {item.quoteTime && (
                                        <div>
                                          <b>Quote Date: </b>{' '}
                                          {format(
                                            new Date(item.quoteTime),
                                            'yyyy-MM-dd hh:mm aa',
                                          )}
                                        </div>
                                      )}
                                      <div>
                                        <b>Confirmed Date: </b>{' '}
                                        {format(
                                          new Date(item.confirmTime),
                                          'yyyy-MM-dd hh:mm aa',
                                        )}
                                      </div>
                                    </ProductId>
                                  )}

                                  {item.orderStatus === 'review' && (
                                    <ProductId>
                                      <b>Quote Date: </b>{' '}
                                      {format(
                                        new Date(item.quoteTime),
                                        'yyyy-MM-dd hh:mm aa',
                                      )}
                                    </ProductId>
                                  )}

                                  <br />
                                  <ProductId>
                                    <b>Prescription: </b>{' '}
                                    {!showPrescription ? (
                                      <TopButton3
                                        onClick={() =>
                                          setShowPrescription(!showPrescription)
                                        }
                                      >
                                        View
                                      </TopButton3>
                                    ) : (
                                      <TopButton3
                                        onClick={() =>
                                          setShowPrescription(!showPrescription)
                                        }
                                      >
                                        Hide
                                      </TopButton3>
                                    )}
                                  </ProductId>
                                  {showPrescription && (
                                    <PrescriptionImg src={item.url} />
                                  )}
                                  <br />
                                  <ProductId>
                                    <b>Order Status: </b>{' '}
                                    <TopButton2>
                                      {item.orderStatus === 'review' ? (
                                        <>
                                          <div>{item.orderStatus}</div>
                                          <TopButton3
                                            disabled={loading}
                                            onClick={e =>
                                              updatePresStatusConfirm(
                                                item._id,
                                              ) && e.preventDefault()
                                            }
                                          >
                                            confirm
                                          </TopButton3>
                                          <TopButton3
                                            disabled={loading}
                                            onClick={e =>
                                              updatePresStatus(item._id) &&
                                              e.preventDefault()
                                            }
                                          >
                                            cancel
                                          </TopButton3>
                                        </>
                                      ) : item.orderStatus === 'receive' ? (
                                        <>
                                          <div>processed</div>
                                          <TopButton3
                                            disabled={loading}
                                            onClick={e =>
                                              updatePresStatusReceived(
                                                item._id,
                                              ) && e.preventDefault()
                                            }
                                          >
                                            {item.orderStatus}
                                          </TopButton3>
                                          <TopButton3
                                            disabled={loading}
                                            onClick={e =>
                                              updatePresStatus(item._id) &&
                                              e.preventDefault()
                                            }
                                          >
                                            cancel
                                          </TopButton3>
                                        </>
                                      ) : (
                                        item.orderStatus
                                      )}
                                    </TopButton2>
                                  </ProductId>
                                </Details>
                              </ProductDetail>
                            </Product>
                          </header>
                        </button>
                        <Hr />
                      </span>
                    )
                  })}
              </Info>
            )}

            <OrderImgWrap>
              {/* {orders?"":<OrderImage src={OrderImg} />} */}
              <OrderImage src={OrderImg} />
            </OrderImgWrap>
          </Bottom>
        </Wrapper>
      </Container>
      <BotNav />
      <Footer />
    </>
  )
}

export default Orders
